import {Component, OnInit} from '@angular/core';
import {Race, RaceType} from '../../../core/model/resources/race';
import {RaceApiService} from '../../../core/services/race-api.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';

export interface RaceSelectResult {
  raceId: number;
  raceTitle: string;
}

@Component({
  selector: 'app-race-dialog',
  templateUrl: './race-dialog.component.html',
  styleUrls: ['./race-dialog.component.scss']
})
export class RaceDialogComponent implements OnInit {

  displayedColumns = ['id', 'title'];

  queryRace = '';
  queryRaceError: string | null = null;
  queryEvent = '';
  queryEventError: string | null = null;
  representativeRaces: Race[] = [];
  events: Race[] = [];

  static async createDialog(dialog: MatDialog): Promise<RaceSelectResult | false> {
    return await dialog.open(RaceDialogComponent, {
      width: '600px',
      maxWidth: '600px'
    }).afterClosed().toPromise();
  }

  constructor(
    private raceApiService: RaceApiService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<RaceDialogComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  async searchRaces(): Promise<void> {
    const query = !!this.queryRace ? this.queryRace.trim() : '';
    const res = await this.raceApiService.searchByQuery(query, RaceType.NormalRace);
    if (res.success && res.data !== undefined) {
      this.queryRaceError = null;
      this.representativeRaces = res.data.filter((race: Race) => race.is_representation);
    } else {
      this.queryRaceError = '検索に失敗しました。語句を変えてもう一度お試しください。';
      this.representativeRaces = [];
    }
  }

  async searchEvents(): Promise<void> {
    const query = !!this.queryEvent ? this.queryEvent.trim() : '';
    const res = await this.raceApiService.searchByQuery(query, RaceType.VirtualRace);
    if (res.success && res.data !== undefined) {
      this.queryEventError = null;
      this.events = res.data;
    } else {
      this.queryEventError = '検索に失敗しました。語句を変えてもう一度お試しください。';
      this.events = [];
    }
  }


  getLabel(race: Race): string | null {
    if (race.is_representation) {
      return '代表';
    } else if (race.moshicom) {
      return 'モシコム';
    } else {
      return null;
    }
  }

  onClickRace(race: Race): void {
    this.dialogRef.close({raceId: race.id, raceTitle: race.title} as RaceSelectResult);
  }

}
