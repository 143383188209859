<h2 mat-dialog-title>配信先選択</h2>

<mat-dialog-content class="mat-typography">
  <mat-tab-group>
    <mat-tab label="大会">

      <mat-form-field class="search-container">
        <mat-label>大会名で検索（前方一致）</mat-label>
        <input matInput type="search" [(ngModel)]="queryRace" (keyup.enter)="searchRaces()">
      </mat-form-field>

      <span *ngIf="queryRaceError" class="error">{{ queryRaceError }}</span>

      <div class="table-container">
        <table mat-table [dataSource]="representativeRaces" class="mat-elevation-z8">

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> id</th>
            <td mat-cell *matCellDef="let race"> {{ race.id }}</td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> 大会名</th>
            <td mat-cell *matCellDef="let race"> {{ race.title }}
              <span *ngIf="getLabel(race)" class="label_badge">{{ getLabel(race) }}</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let race; columns: displayedColumns;" (click)="onClickRace(race)"></tr>
        </table>
      </div>

    </mat-tab>
    <mat-tab label="イベント">

      <mat-form-field class="search-container">
        <mat-label>イベント名で検索（部分一致）</mat-label>
        <input matInput type="search" [(ngModel)]="queryEvent"
               (keyup.enter)="searchEvents()">
      </mat-form-field>

      <span *ngIf="queryEventError" class="error">{{ queryEventError }}</span>

      <div class="table-container">
        <table mat-table [dataSource]="events" class="mat-elevation-z8">

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> id</th>
            <td mat-cell *matCellDef="let race"> {{ race.id }}</td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> 大会名</th>
            <td mat-cell *matCellDef="let race"> {{ race.title }}
              <span *ngIf="getLabel(race)" class="label_badge">{{ getLabel(race) }}</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let race; columns: displayedColumns;" (click)="onClickRace(race)"></tr>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>閉じる</button>
</mat-dialog-actions>

